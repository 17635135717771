import {Box, Grid, Typography} from '@material-ui/core'
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import {
	TemplateContainerComponent,
	CyclisHeaderComponent as CyclisHeader,
	CustomButtonComponent as CustomButton,
} from '@components/index'
import BadRequestPage from '@pages/400'
import NotFoundPage from '@pages/404'
import {RootState} from '@redux/root-reducer'
import {navigate} from '@redux/slices/navigation-slice'
import {PdfIconSvg} from '@svg/index'
import {getQuotationById} from '@templates/QuotationOverview/quotations.slice'
import {NO_GOOGLE_TRANSLATE} from '@utils/constants/util-constants'
import {LanguageContext} from '@utils/context'
import {downloadFileFromUrl} from '@utils/download-helper'
import {SortDirection} from '@utils/hooks/TableDataProvider'
import {toPrettyPrice} from '@utils/string-utils'
import {CommonProps, CyclisDocumentType, Quotation} from '@utils/types'

import {
	getQuotationDocumentDownloadUrl,
	resetQuotationDocument,
} from './quotation-detail.slice'
import useStyles from './style.hook'

/**
 * QuotationDetail
 */
interface QuotationDetailLocation extends Location {
	state: {
		quotation: Quotation
		searchTerm?: string
		sort?: {
			direction: SortDirection
			property: string
		}
	}
}

interface QuotationDetailProps extends CommonProps {
	location: QuotationDetailLocation
}

const QuotationDetail: React.FC<QuotationDetailProps> = ({location}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()
	const dispatch = useDispatch()
	const {t} = useTranslation(undefined, {useSuspense: false})

	const [quotation, setQuotation] = useState<Quotation>(null as any)

	const quotationError = useSelector(
		(state: RootState) => state.quotations.error
	)

	const {activeLanguage} = useContext(LanguageContext)
	const {firstName, lastName} = useSelector((state: RootState) => state.auth)

	const {documentLoading, documentSuccess, documentDownloadUrl} = useSelector(
		(state: RootState) => state.quotationDetail
	)

	const goBack = (): void => {
		dispatch(
			navigate(`/${activeLanguage}/app/quotations`, {
				state: {
					searchTerm:
						(location.state && location.state.searchTerm) || undefined,
					sort: (location.state && location.state.sort) || undefined,
				},
			})
		)
	}

	/**
	 *  Set quotation from location state, or request quotation by ID in query param
	 */
	useEffect(() => {
		if (location.state && location.state.quotation) {
			setQuotation(location.state.quotation)
		} else {
			const params = new URLSearchParams(location.search)
			const quotationId = params.get('id')
			dispatch(getQuotationById(Number(quotationId), setQuotation))
		}
	}, [location, setQuotation])

	/**
	 * Start the browser download once there's a document download url available
	 */
	useEffect(() => {
		if (documentSuccess && documentDownloadUrl) {
			downloadFileFromUrl(documentDownloadUrl)
		}
	}, [documentSuccess, documentDownloadUrl])

	/**
	 * Reset document state on unmount on mount
	 */
	useEffect(() => {
		return (): void => {
			dispatch(resetQuotationDocument())
		}
	}, [])

	const startDocumentDownload = (attachmentIndex: number): void => {
		if (quotation.attachments && quotation.attachments.length) {
			dispatch(
				getQuotationDocumentDownloadUrl(
					quotation.quotationId!,
					quotation.attachments[attachmentIndex].documentId
				)
			)
		}
	}

	const InvolvedPartiesSection: React.FC = () => (
		<Box className={classes.section}>
			<Typography
				id={'quotation-detail-involved-parties'}
				className={classes.sectionTitle}
				variant={'h3'}
			>
				{t('QuotationDetailInvolvedPartiesTitle')}
			</Typography>
			<Box className={classNames(classes.propertyBox, classes.wholeRow)}>
				<Typography
					id={'quotation-detail-customer-label'}
					className={classes.propertyTitle}
					variant={'body1'}
				>
					{t('QuotationDetailCustomer')}
				</Typography>
				<Typography
					id={'quotation-detail-customer-value'}
					className={classNames(
						classes.propertyValue,
						quotation.customer && NO_GOOGLE_TRANSLATE
					)}
					variant={'body1'}
				>
					{quotation.customer}
				</Typography>
			</Box>
			<Box className={classes.propertyBox}>
				<Typography
					id={'quotation-detail-cyclist-label'}
					className={classes.propertyTitle}
					variant={'body1'}
				>
					{t('QuotationDetailCyclist')}
				</Typography>
				<Typography
					id={'quotation-detail-cyclist-value'}
					className={classes.propertyValue}
					variant={'body1'}
				>
					{`${quotation.cyclistFirstName} ${quotation.cyclistLastName}`}
				</Typography>
			</Box>
			<Box className={classes.propertyBox}>
				<Typography
					id={'quotation-detail-cyclist-email-label'}
					className={classes.propertyTitle}
					variant={'body1'}
				>
					{t('QuotationDetailCyclistEmail')}
				</Typography>
				<Typography
					id={'quotation-detail-cyclist-email-value'}
					className={classNames(
						classes.propertyValue,
						quotation.cyclistEmail && NO_GOOGLE_TRANSLATE
					)}
					variant={'body1'}
				>
					{quotation.cyclistEmail || 'N/A'}
				</Typography>
			</Box>
			<Box className={classes.propertyBox}>
				<Typography
					id={'quotation-detail-dealer-label'}
					className={classes.propertyTitle}
					variant={'body1'}
				>
					{t('QuotationDetailDealer')}
				</Typography>
				<Typography
					id={'quotation-detail-dealer-value'}
					className={classNames(
						classes.propertyValue,
						quotation.dealer && NO_GOOGLE_TRANSLATE
					)}
					variant={'body1'}
				>
					{quotation.dealer}
				</Typography>
			</Box>
			<Box className={classes.propertyBox}>
				<Typography
					id={'quotation-detail-reference-label'}
					className={classes.propertyTitle}
					variant={'body1'}
				>
					{t('QuotationDetailReference')}
				</Typography>
				<Typography
					id={'quotation-detail-reference-value'}
					className={classNames(
						classes.propertyValue,
						quotation.reference && NO_GOOGLE_TRANSLATE
					)}
					variant={'body1'}
				>
					{quotation.reference || 'N/A'}
				</Typography>
			</Box>
		</Box>
	)

	const BicycleInformationSection: React.FC = () => (
		<Box className={classes.section}>
			<Typography
				id={'quotation-detail-bicycle-information'}
				className={classes.sectionTitle}
				variant={'h3'}
			>
				{t('QuotationDetailBicycleInformation')}
			</Typography>
			<Box className={classes.propertyBox}>
				<Typography
					id={'quotation-detail-brand-label'}
					className={classes.propertyTitle}
					variant={'body1'}
				>
					{t('QuotationDetailBrand')}
				</Typography>
				<Typography
					id={'quotation-detail-brand-value'}
					className={classNames(classes.propertyValue, NO_GOOGLE_TRANSLATE)}
					variant={'body1'}
				>
					{quotation.brand}
				</Typography>
			</Box>
			<Box className={classes.propertyBox}>
				<Typography
					id={'quotation-detail-model-label'}
					className={classes.propertyTitle}
					variant={'body1'}
				>
					{t('QuotationDetailModel')}
				</Typography>
				<Typography
					id={'quotation-detail-model-value'}
					className={classNames(classes.propertyValue, NO_GOOGLE_TRANSLATE)}
					variant={'body1'}
				>
					{quotation.model}
				</Typography>
			</Box>
			<Box className={classes.propertyBox}>
				<Typography
					id={'quotation-detail-type-label'}
					className={classes.propertyTitle}
					variant={'body1'}
				>
					{t('QuotationDetailType')}
				</Typography>
				<Typography
					id={'quotation-detail-type-value'}
					className={classNames(classes.propertyValue, NO_GOOGLE_TRANSLATE)}
					variant={'body1'}
				>
					{quotation.type}
				</Typography>
			</Box>
			<Box className={classes.propertyBox}>
				<Typography
					id={'quotation-detail-frame-type-label'}
					className={classes.propertyTitle}
					variant={'body1'}
				>
					{t('QuotationDetailFrameType')}
				</Typography>
				<Typography
					id={'quotation-detail-frame-type-value'}
					className={classNames(classes.propertyValue, NO_GOOGLE_TRANSLATE)}
					variant={'body1'}
				>
					{quotation.frameType}
				</Typography>
			</Box>
			<Box className={classes.propertyBox}>
				<Typography
					id={'quotation-detail-frame-size-label'}
					className={classes.propertyTitle}
					variant={'body1'}
				>
					{t('QuotationDetailFrameSize')}
				</Typography>
				<Typography
					id={'quotation-detail-frame-size-value'}
					className={classes.propertyValue}
					variant={'body1'}
				>
					{quotation.frameSize}
				</Typography>
			</Box>
			<Box className={classes.propertyBox}>
				<Typography
					id={'quotation-detail-color-label'}
					className={classes.propertyTitle}
					variant={'body1'}
				>
					{t('QuotationDetailColor')}
				</Typography>
				<Typography
					id={'quotation-detail-color-value'}
					className={classes.propertyValue}
					variant={'body1'}
				>
					{quotation.color}
				</Typography>
			</Box>
			<Box className={classes.propertyBox}>
				<Typography
					id={'quotation-detail-retail-price-label'}
					className={classes.propertyTitle}
					variant={'body1'}
				>
					{t('QuotationDetailRetailPrice')}
				</Typography>
				<Typography
					id={'quotation-detail-retail-price-value'}
					className={classes.propertyValue}
					variant={'body1'}
				>
					{toPrettyPrice(Number(quotation.retailPrice || 0))}
				</Typography>
			</Box>
			<Box className={classes.propertyBox}>
				<Typography
					id={'quotation-detail-accessory-price-label'}
					className={classes.propertyTitle}
					variant={'body1'}
				>
					{t('QuotationDetailTotalAccessoriesPrice')}
				</Typography>
				<Typography
					id={'quotation-detail-accessory-price-value'}
					className={classes.propertyValue}
					variant={'body1'}
				>
					{toPrettyPrice(Number(quotation.totalAccessoryPrice || 0))}
				</Typography>
			</Box>
		</Box>
	)

	const AccessoriesSection: React.FC = () => (
		<Box className={classes.section}>
			<Typography
				id={'quotation-detail-accessories'}
				className={classes.sectionTitle}
				variant={'h3'}
			>
				{t('QuotationAccessoriesListTitle')}
			</Typography>
			{quotation.accessories.map((ac, index) => (
				<Box key={index} className={classes.accessoryBox}>
					<Box className={classes.accessoryQuantityBox}>{ac.quantity}</Box>
					<Typography
						className={classes.accessoryDescription}
						variant={'body1'}
					>
						{ac.description}
					</Typography>
				</Box>
			))}
		</Box>
	)

	const ExtrasSection: React.FC = () => (
		<Box className={classNames(classes.section, classes.extrasSection)}>
			<Typography
				id={'quotation-detail-extras'}
				className={classes.sectionTitle}
				variant={'h3'}
			>
				{t('QuotationDetailExtrasTitle')}
			</Typography>
			<Box className={classes.additionalServicesBox}>
				<Typography
					id={'quotation-detail-additonal-services'}
					className={classes.propertyTitle}
					variant={'body1'}
				>
					{t('QuotationDetailExtrasAdditionalServices')}
				</Typography>
				<Typography
					id={'quotation-detail-insurance'}
					className={
						quotation.insurance ? classes.propertyValue : classes.strikedValue
					}
					variant={'body1'}
				>
					{t('QuotationDetailInsurance')}
				</Typography>
				<Typography
					id={'quotation-detail-assistance'}
					className={
						quotation.assistance ? classes.propertyValue : classes.strikedValue
					}
					variant={'body1'}
				>
					{t('QuotationDetailAssistance')}
				</Typography>
				<Typography
					id={'quotation-detail-maintenance'}
					className={
						quotation.maintenance ? classes.propertyValue : classes.strikedValue
					}
					variant={'body1'}
				>
					{t('QuotationDetailMaintenance')}
				</Typography>
			</Box>
			<Typography
				id={'quotation-detail-extra-budget-label'}
				className={classes.propertyTitle}
				variant={'body1'}
			>
				{t('QuotationDetailExtraBudget')}
			</Typography>
			<Typography
				id={'quotation-detail-extra-budget-value'}
				className={classes.propertyValue}
				variant={'body1'}
			>
				{toPrettyPrice(Number(quotation.extraMaintenance || 0))}
			</Typography>
		</Box>
	)

	const BicycleLeaseSection: React.FC = () => (
		<Box className={classes.section}>
			<Typography
				id={'quotation-detail-lease-title'}
				className={classes.sectionTitle}
				variant={'h3'}
			>
				{t('QuotationDetailBicycleLeaseTitle')}
			</Typography>
			<Box className={classes.propertyBox}>
				<Typography
					id={'quotation-detail-lease-cost-label'}
					className={classes.propertyTitle}
					variant={'body1'}
				>
					{t('QuotationDetailLeaseCost')}
				</Typography>
				<Typography
					id={'quotation-detail-lease-cost-value'}
					className={classes.propertyValue}
					variant={'body1'}
				>
					{toPrettyPrice(Number(quotation.leaseCost || 0))}
				</Typography>
			</Box>
			<Box className={classes.propertyBox}>
				<Typography
					id={'quotation-detail-quotation-id-label'}
					className={classes.propertyTitle}
					variant={'body1'}
				>
					{t('QuotationDetailSONumber')}
				</Typography>
				<Typography
					id={'quotation-detail-quotation-id-value'}
					className={classes.propertyValue}
					variant={'body1'}
				>
					{quotation.salesOrderNumber}
				</Typography>
			</Box>
		</Box>
	)

	const AdministrationSection: React.FC = () => (
		<Box className={classes.section}>
			<Typography
				id={'quotation-detail-administration'}
				className={classes.sectionTitle}
				variant={'h3'}
			>
				{t('QuotationDetailAdministrationTitle')}
			</Typography>
			<Box className={classes.propertyBox}>
				<Typography
					id={'quotation-detail-administration-cost-label'}
					className={classes.propertyTitle}
					variant={'body1'}
				>
					{t('QuotationDetailAdministrationCost')}
				</Typography>
				<Typography
					id={'quotation-detail-administration-cost-value'}
					className={classes.propertyValue}
					variant={'body1'}
				>
					{toPrettyPrice(Number(quotation.administrationCost || 0))}
				</Typography>
			</Box>
		</Box>
	)

	const AttachmentsSection: React.FC = () => (
		<Box className={classes.section}>
			<Typography
				id={'quotation-detail-attachments'}
				className={classes.sectionTitle}
				variant={'h3'}
			>
				{t('QuotationDetailAttachmentsTitle')}
			</Typography>
			{quotation.attachments &&
				quotation.attachments
					.filter((a) => a.documentType === CyclisDocumentType.DEALER_QUOTATION)
					.map((attachment, index) => (
						<Box key={index} className={classes.attachmentsRow}>
							<PdfIconSvg />
							<Typography className={classes.fileName} variant={'body1'}>
								{t(attachment.documentType!)}
							</Typography>
							<CustomButton
								id={`quotation-detail-attachment${index}-download-button`}
								className={classes.deleteButton}
								type={'outlined'}
								text={t('QuotationDetailDownloadButton')}
								propsToDelegate={{
									onClick: (): void => startDocumentDownload(index),
									disabled: documentLoading,
								}}
							/>
						</Box>
					))}
		</Box>
	)

	return quotationError === 'StandardErrorNotFound' ? (
		<NotFoundPage />
	) : quotationError && quotationError.includes('StandardError') ? (
		<BadRequestPage errorMessageFromMiddleware={quotationError} />
	) : (
		<TemplateContainerComponent
			id={'create-quotation-container'}
			className={classes.root}
		>
			<CyclisHeader customerName={`${firstName} ${lastName}`} />
			<Grid container spacing={8}>
				<Grid item xs={2} />
				<Grid item xs={8}>
					<Box onClick={goBack} className={classes.backToOverviewContainer}>
						<ArrowBackSharpIcon
							id={'quotation-detail-back-icon'}
							className={classes.arrowBackIcon}
						/>
						<Typography
							id={'quotation-detail-back-text'}
							className={classes.backText}
							variant={'body1'}
						>
							{t('QuotationDetailBackToOverview')}
						</Typography>
					</Box>
					<Box className={classNames(classes.cardEffect, classes.detailCard)}>
						{quotation && (
							<>
								<Typography
									id={'quotation-detail-quotation-id-label-header'}
									className={classes.propertyTitle}
									variant={'body1'}
								>
									{t('QuotationDetailQuotationSoNumberTitle')}
								</Typography>
								<Typography
									id={'quotation-detail-quotation-id-value-header'}
									className={classes.quotationIdValue}
									variant={'body1'}
								>
									{quotation.salesOrderNumber}
								</Typography>
								<Box className={classes.largeDivider} />
								<InvolvedPartiesSection />
								<BicycleInformationSection />
								<AccessoriesSection />
								<ExtrasSection />
								<BicycleLeaseSection />
								<AdministrationSection />
								<AttachmentsSection />
							</>
						)}
					</Box>
				</Grid>
				<Grid item xs={2} />
			</Grid>
		</TemplateContainerComponent>
	)
}

QuotationDetail.propTypes = {
	location: PropTypes.any.isRequired,
}

export default QuotationDetail
