import {makeStyles} from '@material-ui/core/styles'

import {LARGE_GUTTER_SIZE} from '@styles/mui-theme'

/**
 * Component-scoped styles which should be used as a hook
 * inside QuotationDetail functional component
 */

const useStyles = makeStyles((theme) => ({
	root: {
		background: '#edf2f8',
	},
	backToOverviewContainer: {
		display: 'flex',
		marginTop: theme.spacing(60 / 8),
		marginBottom: theme.spacing(33 / 8),
		cursor: 'pointer',
	},
	backText: {
		fontFamily: 'Montserrat,sans-serif',
		fontWeight: 'bold',
		color: '#08080d',
	},
	arrowBackIcon: {
		marginRight: theme.spacing(8 / 8),
	},
	cardEffect: {
		backgroundColor: '#ffffff',
		borderRadius: theme.spacing(8 / 8),
		border: `solid 1px rgba(0, 0, 0, 0.16)`,
	},
	detailCard: {
		width: `calc(100% - ${LARGE_GUTTER_SIZE * 2}px)`,
		paddingTop: theme.spacing(48 / 8),
		paddingBottom: theme.spacing(150 / 8),
		paddingLeft: '12%',
		marginBottom: theme.spacing(160 / 8),
	},
	propertyTitle: {
		fontWeight: 500,
		fontSize: 16,
		color: '#7e7e80',
		letterSpacing: '0.19px',
	},
	quotationIdValue: {
		fontFamily: 'Montserrat,sans-serif',
		fontWeight: 'bold',
		fontSize: 36,
		color: '#524fa2',
		marginBottom: theme.spacing(24 / 8),
	},
	largeDivider: {
		background: '#edf2f8',
		height: theme.spacing(2 / 8),
		width: '200%',
		marginLeft: '-50%',
	},
	section: {
		paddingTop: theme.spacing(32 / 8),
		paddingBottom: theme.spacing(16 / 8),
		borderBottom: 'solid 1.5px #edf2f8',
		width: '88%',
		display: 'flex',
		flexWrap: 'wrap',
	},
	sectionTitle: {
		fontSize: 24,
		fontWeight: 'bold',
		letterSpacing: '-0.33px',
		color: '#08080d',
		marginBottom: theme.spacing(24 / 8),
		width: '100%',
	},
	propertyValue: {
		color: '#08080d',
		fontSize: 16,
		fontWeight: 'bold',
		fontFamily: 'Montserrat,sans-serif',
	},
	propertyBox: {
		width: '50%',
		marginBottom: theme.spacing(16 / 8),
	},
	wholeRow: {
		width: '100%',
	},
	accessoryBox: {
		display: 'flex',
		marginBottom: theme.spacing(24 / 8),
		width: '100%',
	},
	accessoryQuantityBox: {
		height: theme.spacing(40 / 8),
		width: theme.spacing(40 / 8),
		borderRadius: 8,
		border: 'solid 1px #91a1bb',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: '#524fa2',
		fontWeight: 'bold',
		fontFamily: 'Montserrat,sans-serif',
		fontSize: 16,
		marginRight: theme.spacing(16 / 8),
	},
	accessoryDescription: {
		color: '#7e7e80;',
		fontSize: 16,
		fontWeight: 500,
		alignSelf: 'center',
		width: '50%',
		marginRight: theme.spacing(30 / 8),
	},
	strikedValue: {
		color: '#7e7e80',
		fontSize: 16,
		fontWeight: 'bold',
		fontFamily: 'Montserrat,sans-serif',
		textDecoration: 'line-through',
	},
	extrasSection: {
		flexDirection: 'column',
	},
	additionalServicesBox: {
		marginBottom: theme.spacing(16 / 8),
	},
	attachmentsRow: {
		display: 'flex',
		width: '100%',
		marginTop: theme.spacing(8 / 8),
	},
	fileName: {
		fontFamily: 'Montserrat,sans-serif',
		fontWeight: 'bold',
		color: '#08080d',
		fontSize: 16,
		marginLeft: theme.spacing(20 / 8),
		width: '70%',
	},
	deleteButton: {
		border: 'solid 1px #524fa2',
		color: '#524fa2',
		fontWeight: 600,
		fontSize: 14,
		padding: theme.spacing(0, 24 / 8),
		lineHeight: 'normal',
		height: theme.spacing(32 / 8),
		'&:hover': {
			backgroundColor: 'rgba(255, 255, 255, 0.1)',
			border: 'solid 1px #524fa2',
		},
		'&:active': {
			backgroundColor: 'rgba(255, 255, 255, 0.2)',
			border: 'solid 1px #524fa2',
		},
	},
}))

export default useStyles
